
import { Options, Vue } from 'vue-class-component';
import { AppUserClient } from '@/services/Services';
import { LoginServices } from '@/services/LoginServices';
import InfoModal from '@/components/modals/infoModal.vue';
import store from '@/store';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';

@Options({
    components: {
    }
})
export default class Login extends Vue {

    languageCredential: OM.LanguageCredentialWithLocation = new OM.LanguageCredentialWithLocation();
    phoneNumber: VM.PhoneNumber = new VM.PhoneNumber();
    codeLength: number = 6;

    created() {
        this.languageCredential.preferredCulture = store.state.loggedUser.preferredCulture;
    }

    resetConfirmationCode(){
        if(!this.phoneNumber.prefix || !this.phoneNumber.number) {
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("app_Errore", "Errore"),
                subtitle: this.$localizationService.getLocalizedValue("app_InserisciUnNumeroDiTelefonoValidoPerPoterProcedere", "Inserisci un numero di telefono valido per poter procedere"),
                deny: this.$localizationService.getLocalizedValue("app_HoCapito", "Ho capito"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })

            return;
        }

        var username = this.phoneNumber.prefix + this.phoneNumber.number;
        AppUserClient.resetConfirmationCode(username)
        .then( x => {
            this.$opModal.show(InfoModal, {
                text:this.$localizationService.getLocalizedValue("app_Successo", "Successo"),
                subtitle: this.$localizationService.getLocalizedValue("app_TiEStatoInviatoUnNuovoMessaggio", "Ti è stato inviato un nuovo messaggio"),
                deny: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
        .catch( ex => {
            this.$opModal.show(InfoModal, {
                text:this.$localizationService.getLocalizedValue("app_Errore", "Errore"),
                subtitle: ex.Message,
                deny: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }

    get isDisabled(){
        var pass = this.languageCredential.password.replaceAll(" ", "");
        return pass.length != this.codeLength || !this.phoneNumber.prefix || !this.phoneNumber.number;
    }

    next(){
        this.languageCredential.username = this.phoneNumber.prefix + this.phoneNumber.number;
        AppUserClient.login(this.languageCredential)
        .then( x => {
            StorageServices.setNewTokens(x.token, x.refreshToken);

            LoginServices.LoginCallback(x.token, x.refreshToken);
        }).catch( ex => {
            this.$opModal.show(InfoModal, {
                text:this.$localizationService.getLocalizedValue("app_Errore", "Errore"),
                subtitle: ex.Message,
                deny: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }
}
