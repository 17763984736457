import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex_spacebetween_centered column fp_container text-center" }
const _hoisted_2 = { class: "mb-5" }
const _hoisted_3 = { class: "mt-4" }
const _hoisted_4 = { class: "w-100" }
const _hoisted_5 = { class: "flex_centered column mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_feven = _resolveComponent("header-feven")
  const _component_localized_text = _resolveComponent("localized-text")
  const _component_form_field = _resolveComponent("form-field")
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_header_feven, { class: "mb-4" }),
      _createVNode(_component_localized_text, {
        localizedKey: "app_BentornatoInFeven",
        text: "Bentornato in Feven",
        class: "fev_text5 fw_700"
      })
    ]),
    _createVNode("form", {
      onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args)), ["stop","prevent"]))
    }, [
      _createVNode("div", null, [
        _createVNode(_component_form_field, {
          type: "tel",
          class: "mb-5",
          prefix: _ctx.phoneNumber.prefix,
          "onUpdate:prefix": _cache[1] || (_cache[1] = ($event: any) => (_ctx.phoneNumber.prefix = $event)),
          modelValue: _ctx.phoneNumber.number,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.phoneNumber.number = $event)),
          placeholderKey: "app_Numero",
          placeholderValue: "Numero",
          required: true
        }, null, 8, ["prefix", "modelValue"]),
        _createVNode(_component_localized_text, {
          localizedKey: "app_Password",
          text: "Password",
          class: "fev_text5 fw_700"
        }),
        _createVNode(_component_localized_text, {
          localizedKey: "app_LaPasswordEIlCodiceDiConfermaInviatoInFaseDiRegistrazione",
          text: "La password è il codice di conferma inviato in fase di registrazione.",
          class: "fev_text7 mt-2"
        }),
        _createVNode(_component_form_field, {
          class: "mt-4",
          type: "CodeConfirmation",
          modelValue: _ctx.languageCredential.password,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.languageCredential.password = $event)),
          codeLength: _ctx.codeLength,
          required: true
        }, null, 8, ["modelValue", "codeLength"]),
        _createVNode("div", _hoisted_3, [
          _createVNode(_component_localized_text, {
            localizedKey: "app_LhaiCancellato",
            text: "L'hai cancellato?",
            class: "fev_text5 mt-2"
          }),
          _createVNode(_component_localized_text, {
            localizedKey: "app_InviaDiNuovo",
            text: "Invia di nuovo",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.resetConfirmationCode())),
            class: "fev_text5 fw_700 text_underlined ms-1"
          })
        ])
      ]),
      _createVNode("div", _hoisted_4, [
        _createVNode(_component_localized_text, {
          localizedKey: "app_ProseguendoAccettoITerminiECondizioneELaPrivacyPolicy",
          text: "Proseguendo accetto i <a target='_blank' href='{{termslink}}'>Termini e Condizione</a> e la <a target='_blank' href='{{privacylink}}'>Privacy Policy</a>",
          object: { termslink: _ctx.$localizationService.getTermsAndConditionsLink(), privacylink: _ctx.$localizationService.getPrivacyPolicyLink() },
          class: "fev_text7 mb-3 mt-5"
        }, null, 8, ["object"]),
        _createVNode("button", {
          type: "submit",
          class: "fev_button bg_brand",
          disabled: _ctx.isDisabled
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "app_Login",
            text: "Login"
          })
        ], 8, ["disabled"]),
        _createVNode("div", _hoisted_5, [
          _createVNode(_component_localized_text, {
            localizedKey: "app_NonHaiUnAccount_question",
            text: "Non hai un account?",
            class: "fev_text5"
          }),
          _createVNode(_component_router_link, {
            to: "/",
            class: "bg_brand bg_on_text"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_localized_text, {
                localizedKey: "app_Registrati",
                text: "Registrati",
                class: "fev_text5 fw_700"
              })
            ]),
            _: 1
          })
        ])
      ])
    ], 32)
  ]))
}